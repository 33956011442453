import React, { useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Button, CardText, Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner, Table } from 'reactstrap'
import Flatpickr from 'react-flatpickr';
import { currentEntity, selectThemeColors } from '../../modules/commonModel/Utils';
import ReactSelect from 'react-select'
import AsyncSelect from 'react-select/async'
import { Plus, X } from 'react-feather';
import toast from 'react-hot-toast';
import { CustomerService } from '../../modules/Services/Customers';
import moment from 'moment';
import { InvoiceService } from '../../modules/Services/Invoice';
import ConfirmModal from '../../modules/commonModel/ConfirmModal';
import { useParams } from 'react-router-dom';

function InvoiceModel({
    openInvoice = false,
    setOpenInvoice,
    invoiceId = null,
    setInvoiceId = (null),
    setRenderElementService,
    priceData
}) {
    // const { control, formState: { errors }  } = useForm()
    const { control, handleSubmit, reset, setValue, formState: { errors } } = useForm({ });
    const entity_id = localStorage.getItem('entity_id')
    const auth_user = JSON.parse(localStorage.getItem('auth_user'))
    const currentEntityData = currentEntity()
    const [currentCustomer, setCurrentCustomer] = useState({ value: null, label: 'Select Customer' })
    const [invoiceDate, setInvoiceDate] = useState(new Date())
    // const [currentStatus, setCurrentStatus] = useState({ value: 1, label: 'Created' })
    // const [statusData, setStatusData] = useState([])
    const [loader, setLoader] = useState(false)
    const [invoiceData, setInvoiceData] = useState(null)
    const [invoiceItem, setInvoiceItem] = useState([])
    const [centeredModal, setCenteredModal] = useState(false)
    const { id } = useParams()
    let customerTimeoutId: ReturnType<typeof setTimeout> | null = null;

    const { fields, append, remove } = useFieldArray({
        control,
        name: "invoice"
    });

    const qsDefaultFields = {
        item : '',
        qty : '',
        price : '',
        total : ''
    }

    useEffect(() => {
    //   const dfdfff = [
    //       { id: 1, item: 'kotlin', qty: 45, price: 120, total: 1500},
    //       { id: 2, item: 'fat boy', qty: 10, price: 20, total: 1500}
    //   ]
    }, [])

    function getInvoiceData() {
        InvoiceService.editInvoice(entity_id, invoiceId)
        .then((res: any) => {
            setInvoiceData(res?.data?.response)
            setCurrentCustomer({ value: res?.data?.response?.customer_id, label: res?.data?.response?.customer_name })
            // setCurrentStatus({ value: res?.data?.response?.status?.id, label: res?.data?.response?.status?.label })
            setInvoiceItem(res?.data?.response?.invoice_items)
            
            const date: any = res?.data?.response?.date ? moment(res?.data?.response?.date).format('YYYY-MM-DD') : null
            setInvoiceDate(date)
            reset({
                bill_from_name: currentEntityData?.entity_name,
                bill_from_email: currentEntityData?.email,
                bill_to_name: { value: res?.data?.response?.customer_id, label: res?.data?.response?.customer_name },
                bill_to_email: res?.data?.response?.customer_email
            })
        })
        .catch((err: any) => console.log(err?.response?.data?.message))
    }

    useEffect(() => {
        if (openInvoice) {
            if (invoiceId) {
              getInvoiceData()
            } else {      
                setInvoiceItem(priceData ? [priceData] : [])
                setCurrentCustomer({ value: priceData?.customer_id, label: priceData?.customer_name })
                reset({
                    bill_from_name: currentEntityData?.entity_name,
                    bill_from_email: currentEntityData?.email,
                    bill_to_name: { value: priceData?.customer_id, label: priceData?.customer_name },
                    bill_to_email: priceData?.customer_email,
                    // invoice: dfdfff
                })
            }
        }
    }, [openInvoice, invoiceId])

    const loadOptions = (inputValue: any, callback: any) => {
        if (customerTimeoutId) {
            clearTimeout(customerTimeoutId);
        }
        customerTimeoutId = setTimeout(() => {
            const obj = { 
                getall: 1,
                fields: 'id,customer_name,customer_email',
                customer_name : inputValue
            }
            CustomerService.getCustomerList(entity_id, obj)
            .then((res: any) => {
                callback(res?.data?.response?.map((item: any) => {
                    return { value: item?.id, label: item?.customer_name, customerEmail: item?.customer_email}
                }))
            })
            .catch(err => console.log(err?.response?.data?.message))
        }, 1000)
    }

    // function getInvoiceStatusList() {
    //     InvoiceService.getInvoiceStatusList(entity_id)
    //     .then((res: any) => {
    //       const statusList = res?.data?.response?.map((item: any) => {
    //         return { value: item?.id, label: item?.label }
    //       })
    //       setStatusData(statusList)
    //     })
    //     .catch((err: any) => {
    //       toast.error(err?.response?.data?.message)
    //     })
    // }
    
    // useEffect(() => {
    //     getInvoiceStatusList()
    // }, [])

    const onSubmit = (data: any) => {
        setLoader(true)
        const payload = {
            entity_id: entity_id,
            customer_id: currentCustomer?.value,
            job_id: id,
            date: moment(invoiceDate).format('YYYY-MM-DD'),
            // status: currentStatus?.value,
            amount: invoiceId ? (invoiceItem ? (invoiceItem[0]?.qty * invoiceItem[0]?.price) : 0) : (1 * (priceData?.price_by_job === 1 ? priceData?.job_price : priceData?.price_by_job_legs === 1 ? priceData?.job_leg_sum : 0)),
            invoice_items: [{
                id: invoiceId ? 1 : null,
                item_name: invoiceId ? (invoiceItem ? invoiceItem[0]?.item_name : '') : priceData?.job_name,
                qty: invoiceId ? (invoiceItem ? invoiceItem[0]?.qty : '') : 1,
                price: invoiceId ? (invoiceItem ? (invoiceItem[0]?.qty * invoiceItem[0]?.price) : 0) : (priceData?.price_by_job === 1 ? priceData?.job_price : priceData?.price_by_job_legs === 1 ? priceData?.job_leg_sum : 0),
                description: null
            }]
        }
        InvoiceService[invoiceId ? 'updateInvoice' : 'storeInvoice'](entity_id, payload, invoiceId)
        .then((res: any) => {
            toast.success(res?.data?.message)
            setOpenInvoice(false)
            setRenderElementService(1)
            // setRender(!render)
        })
        .catch((err: any) => {
            toast.error(err?.response?.data?.message)
        })
        .finally(() => setLoader(false))
    }

    useEffect(() => {
        if (!openInvoice) {
            reset({})
            // setCurrentStatus({ value: 1, label: 'Created' })
            setCurrentCustomer({ value: null, label: 'Select Customer' })
        }
    }, [openInvoice])

    function forceDeleteInvoice() {
        setLoader(true)
        InvoiceService.deleteInvoice(entity_id, invoiceId)
        .then((res: any) => {
            toast.success(res?.data?.message)
            setCenteredModal(false)
            setOpenInvoice(false)
            setRenderElementService(1)
            // setRender(!render)
            setInvoiceId(null)
        })
        .catch((err: any) => {
            toast.error(err?.response?.data?.message)
        })
        .finally(() => setLoader(false))
    }
    
  return (
    <>
        <Modal isOpen={openInvoice} className="modal-dialog-centered modal-xl" toggle={() => setOpenInvoice(false)} >
            <ModalBody className="px-sm-5">
                <div>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className='pb-5 px-5'>
                            <Col md='6'>
                                <Label className='d-flex align-items-center me-3'>Invoice Date</Label>
                                <div className='col-8'>
                                    <Flatpickr
                                        className='form-control' 
                                        placeholder='Select Date'
                                        value={invoiceDate}
                                        options={{ 
                                            enableTime: false, 
                                            altInput: true, 
                                            dateFormat: 'Y-m-d H:i',
                                            disableMobile : true
                                        }} 
                                        onChange={(value) => {
                                            setInvoiceDate(value[0])
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col md='6' className='d-flex align-items-center justify-content-end'>
                                <h3 className='mb-0 text-muted'>{invoiceData?.invoice_number}</h3>
                            </Col>
                            {/* <Col md='4' className='d-flex'>
                                <Label className='d-flex align-items-center me-3'>Status</Label>
                                <div className='col-8'>
                                    <ReactSelect
                                        isClearable={false}
                                        className='react-select'
                                        classNamePrefix='select'
                                        theme={selectThemeColors}
                                        options={statusData}
                                        value={currentStatus}
                                        onChange={(data: any) => {
                                            setCurrentStatus(data)
                                        }}
                                    />
                                </div>
                            </Col> */}
                        </Row>
                        <hr className='m-0' />
                        <Row className='mt-10 mb-5 px-5'>
                            {/* <Col md='6'>
                                <div className='mb-7'>
                                    <Label className='form-label' for='bill_from_name'>Bill From</Label>
                                    <Controller name='bill_from_name' control={control} rules={{ required: false }}
                                        render= {({ field }) => (
                                            <Input placeholder='Enter Name' disabled={true} invalid={errors.bill_from_name && true} {...field} />
                                        )}
                                    />
                                    {errors?.bill_from_name && (<small className="text-danger ml-2 ">Name is a required field</small>)}
                                </div>
                                <div>
                                    <Controller name='bill_from_email' control={control} rules={{ required: false }}
                                        render= {({ field }) => (
                                            <Input placeholder='Enter Email' disabled={true} invalid={errors.bill_from_email && true} {...field} />
                                        )}
                                    />
                                    {errors?.bill_from_email && (<small className="text-danger ml-2 ">Email is a required field</small>)}
                                </div>
                            </Col> */}
                            <Col md='6'>
                                <div className='mb-5'>
                                    <Label className='form-label' for='bill_to_name'>Bill To</Label>
                                    <Controller name='bill_to_name' control={control} rules={{ required: true }}
                                        render= {({ field: { onChange } }) => (
                                            <AsyncSelect
                                                isClearable={true}
                                                className='react-select w-100'
                                                classNamePrefix='select'
                                                placeholder='Search Customer'
                                                isRtl={false}
                                                name='callback-react-select'
                                                loadOptions={loadOptions}
                                                value={currentCustomer}
                                                cacheOptions
                                                onChange={(data: any) => {
                                                    onChange(data)
                                                    setCurrentCustomer(data)
                                                    setValue('bill_to_email', data?.customerEmail)
                                                }}
                                            />
                                        )}
                                    />
                                    {errors?.bill_to_name && (<small className="text-danger ml-2 ">Name is a required field</small>)}
                                </div>
                                <div>
                                    <Controller name='bill_to_email' control={control} rules={{ required: false }}
                                        render= {({ field }) => (
                                            <Input placeholder='Enter Email' disabled={true} invalid={errors.bill_to_email && true} {...field} />
                                        )}
                                    />
                                    {errors?.bill_to_email && (<small className="text-danger ml-2 ">Email is a required field</small>)}
                                </div>
                            </Col>
                            <Col md='12' className='mt-3'>
                                <>
                                    { (invoiceData?.customer_full_address || priceData?.customer_full_address) ? 
                                        (invoiceData?.customer_full_address || priceData?.customer_full_address)?.split(',')?.map((item: any) => {
                                            return <p className='mb-0'>{item}</p>
                                        })
                                        : 
                                        <>
                                            <p className='mb-0'>{invoiceData?.customer_address_1 || priceData?.customer_address_1}</p>
                                            <p className='mb-0'>{invoiceData?.customer_address_2 || priceData?.customer_address_2}</p>
                                            <p className='mb-0'>{invoiceData?.customer_city || priceData?.customer_city}</p>
                                            <p className='mb-0'>{invoiceData?.county_name || priceData?.county_name}</p>
                                            <p className='mb-0'>{invoiceData?.countries_name || priceData?.countries_name}</p>
                                            <p className='mb-0'>{invoiceData?.customer_postcode || priceData?.customer_postcode}</p>
                                        </>
                                    }
                                </>
                            </Col>
                        </Row>
                        <Row className='px-5'>
                            <div className='job_table mt-3' style={{ maxHeight: '400px', minHeight: '150px', overflowY: 'scroll' }}>
                                <Table  className='w-100 job_table'>
                                    <thead>
                                        <tr>
                                            <th className='ps-5' style={{width: '50%'}}>ITEM</th>
                                            <th style={{width: '20%'}}>QTY</th>
                                            <th style={{width: '15%'}}>PRICE</th>
                                            <th style={{width: '15%'}}>TOTAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { invoiceItem?.map((item) => {    
                                            return (
                                                <tr>
                                                    <td className='ps-5'>{item?.job_name || item?.item_name}</td>
                                                    <td>{item?.qty || 1}</td>
                                                    <td className='ps-5'>{(item?.price_by_job === 1 ? item?.job_price : item?.price_by_job_legs === 1 ? item?.job_leg_sum : 0) || item?.price || 0}</td>
                                                    <td>{(1 * item?.price_by_job === 1 ? item?.job_price : item?.price_by_job_legs === 1 ? item?.job_leg_sum : 0) || (item?.qty * item?.price || 0)}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </Row>
                        {/* <div>
                            <Row className='my-4'>
                                <Col xs={5} lg={5} className="qs-head">
                                    <CardText>ITEM</CardText>
                                </Col>
                                <Col xs={2} lg={2} className="qs-head">
                                    <CardText >QTY</CardText>
                                </Col>
                                <Col xs={2} lg={2} className="qs-head">
                                    <CardText >PRICE</CardText>
                                </Col>
                                <Col xs={1} lg={2} className="qs-head qs-notes">
                                    <CardText >TOTAL</CardText>
                                </Col>
                                <Col xs={1} lg={1} className="total"></Col>
                            </Row>
                            <div style={{ height: '300px', overflowY: 'scroll', overflowX: 'hidden' }}>
                                { fields?.length > 0 ? fields?.map((item, index) => (
                                    <Row key={item?.id} className="mb-3">
                                        <Col xs={5} lg={5} className="pe-0">
                                            <Controller name={`invoice.${index}.item`} control={control} defaultValue=''
                                                render={({ field }) => (
                                                    <Input type="text" placeholder='Enter Item' {...field} 
                                                        invalid={errors.invoice?.[index]?.item}
                                                        onChange={(e) => {
                                                            field.onChange(e.target.value)
                                                            // setFieldChange(true)
                                                            // updateQuoteService(index)
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Col> 
                                        <Col xs={2} lg={2} className="pe-0">
                                            <Controller name={`invoice.${index}.qty`} control={control} defaultValue='' rules={{required : true}}
                                                render={({ field }) => (
                                                <Input type="number" invalid={errors.invoice?.[index]?.qty}
                                                    placeholder='Enter Qty' 
                                                    {...field} 
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value)
                                                        // setFieldChange(true)
                                                        // updateQuoteService(index)
                                                    }}
                                                />
                                                )}
                                            />
                                        </Col>
                                        <Col xs={2} lg={2} className="pe-0">
                                            <Controller name={`invoice.${index}.price`}  control={control} defaultValue='' rules={{required : true}}
                                                render={({ field }) => (
                                                <Input data-tip data-for={`service-${index}`} type="number" 
                                                    placeholder='Enter Price' 
                                                    invalid={errors.invoice?.[index]?.price}
                                                    {...field} 
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value)
                                                        // setFieldChange(true)
                                                        // updateQuoteService(index)
                                                    }}
                                                />
                                                )}
                                            />
                                        </Col>
                                        <Col xs={2} lg={2} className="pe-0 qs-notes">
                                            <Controller name={`invoice.${index}.total`} control={control} defaultValue='' rules={{ required : true }}
                                                render={({ field }) => (
                                                <Input type="number" placeholder='Charge Total'
                                                    invalid={errors.invoice?.[index]?.total}
                                                    {...field} 
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value)
                                                        // setFieldChange(true)
                                                        // updateQuoteService(index)
                                                    }}
                                                />
                                                )}
                                            />
                                        </Col>
                                        <Col xs={1} lg={1} className="pe-0 remove d-flex align-items-center">
                                            <X size={18} className='mx-5' onClick={() => remove(index)} />
                                            {fields?.length - 1 === index &&
                                                <Plus size={18} onClick={() => append({...qsDefaultFields})} />
                                            }
                                        </Col>
                                    </Row>
                                    )) : (
                                        <Row>
                                            <Col xs={12} lg={12}>
                                                no Record found
                                            </Col>
                                        </Row>
                                    )
                                }
                            </div>
                        </div> */}
                        <Row className='mt-10 p-5'>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    { invoiceId && 
                                        <Button type="button" color="danger" onClick={() => setCenteredModal(true)}>
                                            Delete
                                        </Button>
                                    }
                                </div>
                                <div>
                                    <Button type="button" color="secondary" onClick={() => setOpenInvoice(false)} style={{ marginRight: "10px" }}>
                                        Close
                                    </Button>
                                    <Button type='submit' disabled={loader} color='primary' className='btn-next'>
                                        { loader ? <Spinner size='sm'/> : <span className='align-middle d-sm-inline-block d-none'>Save</span>}
                                    </Button>
                                </div>
                            </div>
                        </Row>
                    </Form>
                </div>
            </ModalBody>
        </Modal>
        <ConfirmModal
            isOpen={centeredModal}
            onHide={() => setCenteredModal(false)}
            ondelete={forceDeleteInvoice}
            loader={loader}
        />
    </>
  )
}

export default InvoiceModel