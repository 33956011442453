import axios from "axios"

function getCustomerList(entity_id: any, param: any) {
    return new Promise((resolve, reject) => {
        axios.get(`${import.meta.env.VITE_APP_API_URL}customers`, { headers: { 'Entity-Id': entity_id },  params : param})
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

function storeCustomer(entity_id: any, param: any) {
    return new Promise((resolve, reject) => {
        axios.post(`${import.meta.env.VITE_APP_API_URL}customers/store`, param, { headers: { 'Entity-Id': entity_id } })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

function updateCustomer(entity_id: any, param: any, id: any) {
    return new Promise((resolve, reject) => {
        axios.put(`${import.meta.env.VITE_APP_API_URL}customers/${id}/update`, param, { headers: { 'Entity-Id': entity_id } })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

function editCustomer(entity_id: any, id: any) {
    return new Promise((resolve, reject) => {
        axios.get(`${import.meta.env.VITE_APP_API_URL}customers/${id}/edit`, { headers: { 'Entity-Id': entity_id } })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

function deleteCustomer(entity_id: any, id: any) {
    return new Promise((resolve, reject) => {
        axios.delete(`${import.meta.env.VITE_APP_API_URL}customers/${id}/delete`, { headers: { 'Entity-Id': entity_id } })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

export const CustomerService = {
    getCustomerList,
    storeCustomer,
    updateCustomer,
    editCustomer,
    deleteCustomer
}