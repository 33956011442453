import axios from "axios"

function getJobList(entity_id: any, param: any) {
    return new Promise((resolve, reject) => {
        axios.get(`${import.meta.env.VITE_APP_API_URL}jobs`, { headers: { 'Entity-Id': entity_id },  params : param})
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

function storeJob(entity_id: any, param: any) {
    return new Promise((resolve, reject) => {
        axios.post(`${import.meta.env.VITE_APP_API_URL}jobs/store`, param, { headers: { 'Entity-Id': entity_id } })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

function updateJob(entity_id: any, param: any, id: any) {
    return new Promise((resolve, reject) => {
        axios.put(`${import.meta.env.VITE_APP_API_URL}jobs/${id}/update`, param, { headers: { 'Entity-Id': entity_id } })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

function editJob(entity_id: any, id: any) {
    return new Promise((resolve, reject) => {
        axios.get(`${import.meta.env.VITE_APP_API_URL}jobs/${id}/edit`, { headers: { 'Entity-Id': entity_id } })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

function deleteJob(entity_id: any, id: any) {
    return new Promise((resolve, reject) => {
        axios.delete(`${import.meta.env.VITE_APP_API_URL}jobs/${id}/delete`, { headers: { 'Entity-Id': entity_id } })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

function getJobStatusList(entity_id: any) {
    return new Promise((resolve, reject) => {
        axios.get(`${import.meta.env.VITE_APP_API_URL}jobs/status`, { headers: { 'Entity-Id': entity_id } })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

export const JobService = {
    getJobList,
    storeJob,
    updateJob,
    editJob,
    deleteJob,
    getJobStatusList
}