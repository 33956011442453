
import {FC} from 'react'
import {useAuth} from '../../../../app/modules/auth'
import { Power } from 'react-feather'

const HeaderUserMenu: FC = () => {
  const {logout} = useAuth()
  
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold fs-6 w-150px'
      data-kt-menu='true'
    >
      <div className='d-flex p-4 cursor-pointer' onClick={logout}>
        <Power size={18} style={{color: '#78829d'}} />
        <a onClick={logout} className='menu-link ms-4' style={{color: '#78829d'}}>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
