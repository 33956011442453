export const selectThemeColors = (theme: any) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: '#7367f01a', // for option hover bg-color
      primary: '#7367f0', // for selected option bg-color
      // neutral10: '#7367f0', // for tags bg-color
      neutral20: '#ededed', // for input border-color
      neutral30: '#ededed' // for input hover border-color
    }
  })

  export const selectStyle = {
    control: (style: any) => ({ ...style, border: '1px solid #ea5455'})
  }

  export const currentEntity = () => {
    const auth_user = JSON.parse(localStorage.getItem('auth_user'))
    const entity_id = localStorage.getItem('entity_id')

    let entityData = null
    auth_user?.entities?.map((item: any) => {
      if (item?.id === Number(entity_id)) entityData = item
    })
    return entityData
  }

  export function preventDefualt(e: any) {
    if (e.key == '-' || e.key == '+' || e.key == '*' || e.key == '/' || e.key == 'e' || e.key == '.' || e.keyCode === 32  || e.keyCode === 13) {
      e.preventDefault();
      return false;
    }
  }