import React, { Fragment, useEffect, useState } from 'react'
import { Col, Card, Form, Input, Label, Row, Button, Spinner } from "reactstrap"
import { Controller, useForm } from "react-hook-form"
import { useNavigate, useParams } from 'react-router'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import toast from 'react-hot-toast'
import { DriverService } from '../../modules/Services/Drivers'
import ConfirmModal from '../../modules/commonModel/ConfirmModal'
import UILoader from '../../modules/commonModel/Ui-loader'
import ComponentSpinner from '../../modules/apps/user-management/users-list/components/loading/Loading-spinner'

const AddDriver: React.FC = () => {
  const { control, handleSubmit, reset, formState: { errors } } = useForm()
  const {id} = useParams()
  const addMode = !id
  const [officePhone, setOfficePhone] = useState('')
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)
  const [centeredModal, setCenteredModal] = useState(false)
  const [pending, setPending] = useState(false)
  const [editLoader, setEditLoader] = useState(false)
  const [notDisplayPlanner, setNotDisplayPlanner] = useState(false)
  const phoneStyle = { backgroundColor:"white", backgroundClip:"padding-box", border:"1px solid #d8d6de", borderRadius:"0.357rem", width:"100%" }
  const entity_id = localStorage.getItem('entity_id')
  const EmailPattern = /^[a-zA-Z0-9.'’‘’'_%+-]+@[a-zA-Z0-9.'’‘’'-]+\.[a-z]{0,30}$/

  function fetchFormData(data: any) {
    setLoader(true)
    const obj = {
      entity_id: entity_id,
      driver_name: data?.name,
      driver_email: data?.email,
      driver_mobile: officePhone,
      is_not_display_on_planner: +notDisplayPlanner
    }
    if (id) delete obj.entity_id

    DriverService[id ? 'updateDriver' : 'storeDriver'](entity_id, obj, id)
      .then((res: any) => {
        toast.success(res?.data?.message)
        navigate('/driver')
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message)
      })
      .finally(() => setLoader(false))
  }

  function editData() {
    setEditLoader(true)
    DriverService.editDriver(entity_id, id)
      .then((res: any) => {
        const obj = {
          name: res.data?.response?.driver_name,
          email: res.data?.response?.driver_email,
          mobile: res.data?.response?.driver_mobile
        }
        reset(obj)
        setNotDisplayPlanner(res.data?.response?.is_not_display_on_planner)
        setOfficePhone(res.data?.response?.driver_mobile)
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message)
      })
      .finally(() => setEditLoader(false))
  }

  useEffect(() => {
    if (id) {
      editData()
    }
  }, [id])

  useEffect(() => {
    if (addMode) {
      reset({ mobile: 353 })
      setOfficePhone('353')
    }
  }, [addMode])

  function forceDeleteDriver() {
    setPending(true)
    DriverService.deleteDriver(entity_id, id)
    .then((res: any) => {
        toast.success(res?.data?.message)
        setCenteredModal(false)
        navigate('/driver')
    })
    .catch((err: any) => {
        toast.error(err?.response?.data?.message)
    })
    .finally(() => setPending(false))
  }

  return (
    <>
      <Fragment>
        <ConfirmModal
            isOpen={centeredModal}
            onHide={() => setCenteredModal(false)}
            ondelete={forceDeleteDriver}
            loader={pending}
        />
        <Card className='p-10'>
          <UILoader blocking={editLoader} loader={<ComponentSpinner />} overlayColor='rgba(245, 245, 240, .1)' className={editLoader ? 'loader-blur' : ''}>
            <Form onSubmit={handleSubmit(fetchFormData)}>
              <Row>
                <Col md= '6' className='mb-6'>
                  <Label className='form-label' for='name'>Name</Label>
                  <Controller name='name' control={control} rules={{required: true}}
                    render={({ field }) => <Input placeholder='Enter Name' invalid={ errors.name && true } {...field} />} 
                  />
                  {errors.name && (<small className="text-danger ml-2 ">Name is a required field</small>)}
                </Col>
                <Col md='6' className='mb-6'>
                  <Label className='form-label' for='email'>Email</Label>
                  <Controller name='email' control={control} defaultValue='' rules={{required : true, pattern: EmailPattern}}
                    render={({ field }) => ( 
                      <Input placeholder='johndoe@gmail.com' invalid={errors.email && true} {...field} />)} 
                    />
                    {errors.email?.type === 'required' && (<small className="text-danger ml-2">Email is a required field</small>)}
                    {errors.email?.type === 'pattern' && (<small className="text-danger ml-2">Invalid Email Address</small>)}
                </Col>
                <Col md='6' className='mb-6'>
                  <Label className='form-label' for='Mobile'>Mobile</Label>
                  <Controller name='mobile' control={control} defaultValue='' rules={{required : true}}
                    render={({ field: {onChange} }) => ( 
                    // <PhoneInput countryCodeEditable={false} inputStyle={phoneStyle} value={officePhone} onChange={e => { onChange(e); setOfficePhone(e) }} />
                    <PhoneInput inputStyle={phoneStyle} value={officePhone} onChange={e => { onChange(e); setOfficePhone(e) }} />
                  )} />
                    {errors.mobile && (<small className="text-danger ml-2">Mobile is a required field</small>)}
                </Col>
                <Col md='12' className='mb-6'>
                  <Input type='checkbox' className='me-5' checked={notDisplayPlanner} onChange={(e) => setNotDisplayPlanner(e.target.checked)} />
                  <Label className='form-label'>Do not display on Planner</Label>
                </Col>
              </Row>
              <div className='d-flex justify-content-between'>
                <div>
                  { id &&
                    <Button type="button" color="danger" onClick={() => setCenteredModal(true)} >
                      Delete
                    </Button>
                  } 
                </div>
                <div>
                  <Button type="button" color="secondary" onClick={() => navigate("/driver")} style={{ marginRight: "10px" }}>
                    Close
                  </Button>
                  <Button type='submit' color='primary' disabled={loader} className='btn-next'>
                    { loader ? <Spinner size='sm'/> : <span className='align-middle d-sm-inline-block d-none'>Save</span>}
                  </Button>
                </div>
              </div>
            </Form>
          </UILoader>
        </Card>
      </Fragment>
    </>
  )
}

export {AddDriver}